.widgetStandardProduct {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 2147483641;
  font-family: sans-serif;
}
.inside {
  position: static;
}
.logo {
  margin-left: 10px;
  height: 36px;
}
.bar {
  display: flex;
  flex: 0 0 48px;
  align-items: center;
  padding: 6px;
  background-color: var(--primary-lighter);
  z-index: 1;
}
.back,
.close {
  box-sizing: content-box;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 50%;
  padding: 6px;
}
.back:hover,
.openStay:hover,
.close:hover {
  background-color: var(--primary-light);
}
.container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  background-color: var(--primary-lighter);
  user-select: none;
}
.backSvg,
.stayIconSvg,
.closeSvg {
  fill: var(--primary-dark);
}

.openStay {
  display: flex;
  color: var(--text-foreground);
  padding: 3px 0 3px 6px;
  border-radius: 3px;
  transition: all 240ms cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}
.rightBlock {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: center;
}

.stayIcon {
  width: 24px;
  height: 24px;
  box-sizing: content-box;
  padding: 6px;
}
.stayDates {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
